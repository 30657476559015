import {
  Component,
  forwardRef,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  ViewChild,
  ElementRef,
  ViewRef,
  Renderer2,
  inject,
  DestroyRef,
  AfterViewInit,
  TemplateRef,
  Output,
  EventEmitter,
  Input,
  OnDestroy,
  signal,
  WritableSignal,
  effect,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { Node, Schema } from 'prosemirror-model';
import { schema as basicSchema } from 'prosemirror-schema-basic';
import { EditorState } from 'prosemirror-state';
import { EditorView } from 'prosemirror-view';
import { undo, redo, history } from 'prosemirror-history';
import { baseKeymap } from 'prosemirror-commands';
import { keymap } from 'prosemirror-keymap';
import autocomplete, {
  KEEP_OPEN,
  AutocompleteAction,
  ActionKind,
  FromTo,
  closeAutocomplete,
} from 'prosemirror-autocomplete';
import {
  MarkdownParser,
  defaultMarkdownParser,
  defaultMarkdownSerializer,
} from 'prosemirror-markdown';

import {
  BehaviorSubject,
  Subject,
  debounceTime,
  fromEvent,
  switchMap,
  tap,
} from 'rxjs';
import _ from 'lodash';

import { DataService } from 'src/app/core/data.service';
import { NotificationService } from 'src/app/core/notification.service';

import { Constants } from 'src/app/shared/globals/constants';
import { User } from 'src/app/shared/models/entities/settings/user.model';
import { ScrollToService } from 'src/app/shared/services/scroll-to.service';
import { InfoPopupService } from 'src/app/shared/components/features/info-popup/info-popup.service';

import { md } from './markdown';

@Component({
  selector: 'tmt-rich-editor-box',
  templateUrl: './rich-editor-box.component.html',
  styleUrls: ['./rich-editor-box.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RichEditorBoxComponent),
      multi: true,
    },
  ],
})
export class RichEditorBoxComponent
  implements ControlValueAccessor, AfterViewInit, OnChanges, OnDestroy
{
  @ViewChild('popup') private suggestionsEl: TemplateRef<HTMLElement>;

  @Input() public maxLength = 2 ** 11;
  @Input() public placeholder = 'shared.comments.placeholder';
  @Input() public loadLimit = 50;
  @Input() public mentionedUserIds: string[];

  @Output() public focused$ = new EventEmitter<boolean>();
  @Output() public mentionedUserIds$ = new EventEmitter<string[]>();

  public selectedSuggestion: any;
  public suggestions: any = [];
  public suggestionsLoading$ = new BehaviorSubject<boolean>(true);
  public autocompleteValue$ = new Subject<string>();
  public popupId: string;
  public content: string;
  public contentLength: number;
  public readonly: boolean;
  public loadedPartly: boolean;
  public propagateChange = (_: any) => null;
  public propagateTouch = () => null;

  private mentionIds = signal<string[]>([]);
  private editorView: EditorView;
  private range: FromTo | null;
  private nodes = basicSchema.spec.nodes.remove('image').addToEnd('mention', {
    inline: true,
    group: 'inline',
    selectable: false,
    atom: true,
    attrs: {
      id: {},
      label: {},
    },
    toDOM: (node) => [
      'span',
      { class: 'mention', 'data-id': node.attrs.id },
      node.attrs.label,
    ],
    parseDOM: [
      {
        tag: 'span.mention',
        getAttrs: (dom) => ({
          id: dom.getAttribute('data-id'),
          label: dom.textContent,
        }),
      },
    ],
  });
  private markdownParser: MarkdownParser;
  private readonly destroyRef = inject(DestroyRef);

  constructor(
    private dataService: DataService,
    private notificationService: NotificationService,
    private infoPopupService: InfoPopupService,
    private scrollToService: ScrollToService,
    private renderer: Renderer2,
    private cdr: ChangeDetectorRef,
    private el: ElementRef<HTMLElement>,
  ) {
    effect(() => {
      this.mentionedUserIds$.emit(this.mentionIds());
    });
  }

  public ngAfterViewInit(): void {
    const newSchema = new Schema({
      nodes: this.nodes,
      marks: basicSchema.spec.marks.remove('link'),
    });

    defaultMarkdownSerializer.nodes['mention'] = (state, node) => {
      state.write(`${node.attrs.label}`);
    };
    defaultMarkdownParser.tokens['mention'] = {
      node: 'mention',
      getAttrs: (tok) => ({
        id: tok.attrGet('data-id'),
        label: tok.content,
      }),
    };

    const notRegisteredNodes = [
      'list_item',
      'bullet_list',
      'ordered_list',
      'link',
      'image',
    ];
    notRegisteredNodes.forEach((key) => {
      delete defaultMarkdownParser.tokens[key];
    });

    this.markdownParser = new MarkdownParser(
      newSchema,
      md,
      defaultMarkdownParser.tokens,
    );

    const state = EditorState.create({
      schema: newSchema,
      plugins: [
        ...autocomplete({
          triggers: [{ name: 'mention', trigger: '@' }],
          reducer: (action) => this.handleAutocomplete(action),
        }),
        history(),
        keymap({
          'Mod-z': undo,
          'Mod-y': redo,
          'Shift-Enter': (state) => {
            const { schema, tr } = state;
            this.editorView.dispatch(
              tr.replaceSelectionWith(schema.nodes.hard_break.create()),
            );

            return true;
          },
        }),
        keymap(baseKeymap),
      ],
    });

    this.editorView = new EditorView(this.el.nativeElement, {
      state,
      dispatchTransaction: (transaction) => {
        this.contentLength = transaction.doc.content.size - 2; // TODO: :thinking_face:
        this.cdr.markForCheck();

        if (transaction.doc.content.size >= this.maxLength) {
          return;
        }

        this.editorView.updateState(this.editorView.state.apply(transaction));
        this.content = defaultMarkdownSerializer.serialize(
          this.editorView.state.doc,
        );

        this.propagateChange(this.content);
      },
      nodeViews: {
        mention: (node) =>
          new MentionView(node, this.renderer, this.mentionIds),
      },
      attributes: {
        class: 'comments-input', // TODO: make like options
      },
    });

    this.initSubscribers();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['mentionedUserIds']) {
      this.mentionIds.set(this.mentionedUserIds ?? []);
    }
  }

  public ngOnDestroy(): void {
    this.editorView.destroy();
  }

  public writeValue(value: any): void {
    this.content = value;
    this.updateEditorContent();

    if (!(this.cdr as ViewRef).destroyed) {
      this.cdr.markForCheck();
    }
  }

  public registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.propagateTouch = fn;
  }

  public setDisabledState?(isDisabled: boolean): void {
    this.readonly = isDisabled;

    if (!(this.cdr as ViewRef).destroyed) {
      this.cdr.markForCheck();
    }
  }

  public onBlur(): void {
    this.propagateTouch();
  }

  /**
   * Inserts node with selected suggestion.
   *
   * @param suggestion user or something else.
   */
  public onSuggestionClick(suggestion: any): void {
    this.insertMention(
      `@${suggestion?.email.split('@')[0]}`,
      suggestion.id,
      this.range,
    );
    closeAutocomplete(this.editorView);
    this.editorView.focus();
  }

  private openUsersPopup(): void {
    if (this.popupId) {
      this.infoPopupService.close(this.popupId);
    }

    this.popupId = this.infoPopupService.open({
      target: {
        getBoundingClientRect: () =>
          this.el.nativeElement
            .querySelector('.autocomplete')
            ?.getBoundingClientRect(),
        contextElement: this.el.nativeElement.querySelector('.ProseMirror'),
      },
      data: {
        templateRef: this.suggestionsEl,
      },
      containerStyles: {
        padding: 0,
        overflow: 'hidden',
      },
      isHideArrow: true,
      clickOutsideEnabled: false,
    });
  }

  private updateEditorContent(): void {
    this.editorView?.dispatch(
      this.editorView.state.tr.replaceWith(
        0,
        this.editorView.state.doc.content.size,
        this.markdownParser.parse(this.content).content,
      ),
    );
  }

  private scrollToSelectRow(): void {
    if (this.selectedSuggestion && this.popupId) {
      this.scrollToService.scrollTo(this.selectedSuggestion.id, 'suggestions');
    }
  }

  private insertText(text: string, range?: FromTo): void {
    const { from, to } = range ?? this.editorView.state.selection;
    this.editorView.dispatch(
      this.editorView.state.tr.deleteRange(from, to).insertText(text),
    );
  }

  private insertMention(label: string, id: string, range?: FromTo): void {
    const { from, to } = range ?? this.editorView.state.selection;

    const { schema, tr } = this.editorView.state;
    const mention = schema.nodes.mention.create({ id, label });

    this.editorView.dispatch(
      tr.deleteRange(from, to).replaceSelectionWith(mention),
    );
  }

  /**
   * Autocomplete plugin handler.
   *
   * @param action AutocompleteAction.
   * @returns `boolean` or `KEEP_OPEN` - to keep the suggestion open after selecting.
   */
  private handleAutocomplete(
    action: AutocompleteAction,
  ): boolean | typeof KEEP_OPEN {
    switch (action.kind) {
      case ActionKind.open:
        this.range = action.range;
        this.autocompleteValue$.next('');
        this.openUsersPopup();

        return true;
      case ActionKind.up: {
        const currentIndex = !this.selectedSuggestion
          ? 0
          : this.suggestions.findIndex(
              (el) => el.id === this.selectedSuggestion.id,
            );

        if (currentIndex) {
          this.scrollToSelectRow();
          this.selectedSuggestion = this.suggestions[currentIndex - 1];
          this.cdr.markForCheck();
        }

        return true;
      }
      case ActionKind.down: {
        const currentIndex = !this.selectedSuggestion
          ? 0
          : this.suggestions.findIndex(
              (el) => el.id === this.selectedSuggestion.id,
            );

        if (currentIndex !== this.suggestions.length - 1) {
          this.scrollToSelectRow();
          this.selectedSuggestion = this.suggestions[currentIndex + 1];
          this.cdr.markForCheck();
        }

        return true;
      }
      case ActionKind.filter:
        this.range = action.range;
        this.autocompleteValue$.next(action.filter);

        return KEEP_OPEN;
      case ActionKind.enter: {
        this.insertMention(
          `@${this.selectedSuggestion?.email.split('@')[0]}`,
          this.selectedSuggestion?.id,
          action.range,
        );

        return true;
      }
      case ActionKind.close:
        this.infoPopupService.close(this.popupId);

        return true;
      default:
        return false;
    }
  }

  private initSubscribers(): void {
    fromEvent(this.editorView.dom, 'focus')
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => this.focused$.emit(true));

    fromEvent(this.editorView.dom, 'blur')
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => this.focused$.emit(false));

    this.autocompleteValue$
      .pipe(
        tap(() => {
          this.suggestionsLoading$.next(true);
        }),
        debounceTime(Constants.textInputClientDebounce),
        switchMap((search) => {
          this.suggestionsLoading$.next(true);

          const dataParams: any = {
            top: this.loadLimit,
            select: ['id', 'name', 'email'],
            filter: [{ isActive: true }],
            orderBy: 'name',
          };

          if (search.trim()) {
            dataParams.filter.push({
              or: [
                {
                  // eslint-disable-next-line @typescript-eslint/naming-convention
                  'tolower(name)': {
                    contains: search.trim().toLowerCase(),
                  },
                },
                {
                  // eslint-disable-next-line @typescript-eslint/naming-convention
                  'tolower(email)': {
                    contains: search.trim().toLowerCase(),
                  },
                },
              ],
            });
          }

          return this.dataService.collection('Users').query<User[]>(dataParams);
        }),
      )
      .subscribe((data) => {
        this.loadedPartly = data.length && data.length === this.loadLimit;

        this.suggestions = data;
        this.selectedSuggestion = this.suggestions[0];

        this.suggestionsLoading$.next(false);
      });
  }
}

// TODO: check interface NodeView for more
class MentionView {
  public dom: HTMLElement;

  private clickListener: () => void;

  constructor(
    private node: Node,
    private renderer: Renderer2,
    private mentionIds: WritableSignal<string[]>,
  ) {
    this.dom = renderer.createElement('span');
    this.dom.textContent = node.attrs.label;
    renderer.addClass(this.dom, 'mention');

    if (node.attrs.id) {
      mentionIds.update((mentions) => _.uniq(mentions.concat(node.attrs.id)));
    }

    this.clickListener = renderer.listen(this.dom, 'click', () => {
      console.log(node.attrs);
    });
  }

  public stopEvent(event: any): boolean {
    return event.type === 'click';
  }

  public destroy(): void {
    if (this.node.attrs.id) {
      this.mentionIds.update((mentions) =>
        mentions.filter((id) => id !== this.node.attrs.id),
      );
    }

    this.clickListener();
  }
}
